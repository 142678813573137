import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const mobile = [
  {
    path: "/",
    name: "首页_晋城市鼎峰网络科技有限公司",
    redirect: "/mobile/index",
    component: () => import("../views/mobile/home/home"),
    
    children: [
      {
        path: "/mobile/index",
        name: "首页",
        component: () => import("../views/mobile/index/index"),
      },
      {
        path: "/mobile/exploit",
        name: "定制开发",
        component: () => import("../views/mobile/index/exploit"),
      },
      {
        path: "/mobile/template",
        name: "快速建站",
        component: () => import("../views/mobile/index/template"),
      },
      {
        path: "/mobile/seekadvicefrom",
        name: "在线咨询",
        component: () => import("../views/mobile/index/seekadvicefrom"),

      },
      {
        path: "/mobile/knowledge",
        name: "知识产权",
        component: () => import("../views/mobile/index/knowledge"),
      },
      {
        path: "/mobile/xwactive",
        name: "新闻动态",
        component: () => import("../views/mobile/index/lingsan/xwactive"),
      },
      {
        path: "/mobile/xwparticulars",
        name: "新闻详情",
        component: () => import("../views/mobile/index/lingsan/xwparticulars"),
      },
      {
        path: "/mobile/idcserve",
        name: "IDC服务",
        component: () => import("../views/mobile/index/idcserve"),
      },
      {
        path: "/mobile/finance",
        name: "金融云解决方案",
        component: () => import("../views/mobile/index/finance"),
      },
      {
        path: "/mobile/gamecloud",
        name: "游戏云解决方案",
        component: () => import("../views/mobile/index/gamecloud"),
      },
      {
        path: "/mobile/commercecloud",
        name: "电商云解决方案",
        component: () => import("../views/mobile/index/commercecloud"),
      },
      {
        path: "/mobile/websitecloud",
        name: "网站云解决方案",
        component: () => import("../views/mobile/index/websitecloud"),
      },
      {
        path: "/mobile/mobilecloud",
        name: "移动云解决方案",
        component: () => import("../views/mobile/index/mobilecloud"),
      },
      {
        path: "/mobile/jicheng",
        name: "系统集成",
        component: () => import("../views/mobile/index/lingsan/jicheng"),
      },
      {
        path: "/mobile/fuwuqi",
        name: "服务器租赁",
        component: () => import("../views/mobile/index/lingsan/fuwuqi"),
      },
      {
        path: "/mobile/yuming",
        name: "域名注册及备案",
        component: () => import("../views/mobile/index/lingsan/yuming"),
      },
      {
        path: "/mobile/ruodian",
        name: "弱电工程",
        component: () => import("../views/mobile/index/lingsan/ruodian"),
      },
      {
        path: "/mobile/maintenance",
        name: "运维",
        component: () => import("../views/mobile/index/lingsan/yunwei"),
      },
      {
        path: "/mobile/ssl",
        name: "SSL",
        component: () => import("../views/mobile/index/lingsan/ssl"),
      },
      // 合作伙伴
      {
        path: "/mobile/cooperative",
        name: "合作伙伴",
        component: () => import("/src/views/mobile/index/cooperative"),
      },
      {
        path: "/mobile/theServer",
        name: "服务器租赁",
        component: () => import("/src/views/mobile/index/theServer"),
      },
      // APP设计
      {
        path: "/mobile/designAdd",
        name: "APP设计",
        component: () => import("/src/views/mobile/index/designAdd"),
      },
      // APP开发
      {
        path: "/mobile/caseAdd",
        name: "APP开发",
        component: () => import("/src/views/mobile/index/caseAdd"),
      },
      // 移动端开发详情
      {
        path: "/mobile/caseAdddetails",
        name: "APP开发",
        component: () => import("/src/views/mobile/index/caseAdddetails"),
      },
      // 岗位详情
      {
        path: "/mobile/about/position",
        name: "岗位详情",
        component: () => import("/src/views/mobile/index/aboutitem/position"),
      },
      {
        path: "/mobile/about",
        name: "关于我们",
        component: () => import("../views/mobile/index/about"),
        redirect: "/mobile/about/jianjie",

        children: [
          {
            path: "/mobile/about/jianjie",
            name: "公司简介",
            component: () => import("../views/mobile/index/aboutitem/jianjie"),
          },
          {
            path: "/mobile/about/zhaopin",
            name: "招贤纳士",
            component: () => import("../views/mobile/index/aboutitem/zhaopin"),
          },
          {
            path: "/mobile/about/position",
            name: "岗位详情",
            component: () => import("../views/mobile/index/aboutitem/position"),
          },
          {
            path: "/mobile/about/licheng",
            name: "企业历程",
            component: () => import("../views/mobile/index/aboutitem/licheng"),
          },
          {
            path: "/mobile/about/liuyan",
            name: "留言",
            component: () => import("../views/mobile/index/aboutitem/liuyan"),
          },
        ],
      },
    ],
  },
  // 登录
  {
    path: "/mobile/login",
    name: "登录",
    component: () => import("../views/mobile/login/login"),
  },
  // 注册
  {
    path: "/mobile/register",
    name: "注册",
    component: () => import("../views/mobile/login/register"),
  },
  // 忘记密码
  {
    path: "/mobile/wangji",
    name: "忘记密码",
    component: () => import("../views/mobile/login/wangji"),
  },
  // 个人中心
  {
    path: "/mobile/personal",
    name: "个人中心",
    component: () => import("../views/mobile/personal/index"),
  },
  // 报价咨询
  {
    path: "/mobile/consulting",
    name: "报价咨询",
    component: () => import("../views/mobile/personal/consulting"),
  },
  // 报价记录
  {
    path: "/mobile/record",
    name: "报价记录",
    component: () => import("../views/mobile/personal/record"),
  },
  // 个人中心
  {
    path: "/mobile/mypersonal",
    name: "报价记录",
    component: () => import("../views/mobile/personal/mypersonal"),
  },
  // 修改昵称
  {
    path: "/mobile/username",
    name: "修改昵称",
    component: () => import("/src/views/mobile/personal/username"),
  },
  // 简历投递
  {
    path: "/mobile/delivery",
    name: "简历投递",
    component: () => import("/src/views/mobile/index/aboutitem/delivery"),
  },
];

const pc = [
  {
    path: "/",
    redirect: "/home/index",
    name: "晋城网站建设|晋城软件开发|晋城APP开发|晋城小程序开发|晋城商标注册|晋城IDC|晋城手机软件_晋城市鼎峰网络科技有限公司",
    component: () => import("../views/pc/home/index"),

    children: [
      {
        path: "/home/index",
        name: "晋城网站建设|晋城软件开发|晋城APP开发|晋城小程序开发|晋城商标注册|晋城IDC|晋城手机软件_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/index"),
      },
      {
        path: "/home/customize",
        name: "定制开发_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/customize"),
      },
      {
        path: "/home/internetthings",
        name: "物联网开发_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/Internetthings.vue"),
      },
      {
        path: "/home/internetthingsdatst",
        name: "物联网开发详情_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/Internetthingsdatst.vue"),
      },
      {
        path: "/home/template",
        name: "快速建站_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/template"),
      },
      {
        path: "/home/property",
        name: "知识产权_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/property"),
      },
      // 全部商标
      {
        path: "/home/addbrand",
        name: "合作伙伴_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/addbrand.vue"),
      },

      // 新闻动态
      {
        path: "/home/newsActive",
        name: "新闻动态_晋城市鼎峰网络科技有限公司",
        component: () =>
          import("../views/pc/journalism_dynamic/journalism_dynamic"),
      },
      // 新闻详情
      {
        path: "/home/newsDetails",
        name: "新闻详情_晋城市鼎峰网络科技有限公司",
        component: () =>
          import("../views/pc/journalism_particulars/journalism_particulars"),
      },
      // 集成系统
      {
        path: "/home/integration",
        name: "集成系统_晋城市鼎峰网络科技有限公司",
        component: () =>
          import("../views/pc/integrated_system/integrated_system"),
      },
      // 服务器租赁
      {
        path: "/home/server",
        name: "服务器租赁_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/server/server"),
      },
      // 弱电工程
      {
        path: "/home/weakCurrent",
        name: "弱电工程_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/weak_current/weak_current"),
      },
      // 域名注册及备案
      {
        path: "/home/domain",
        name: "域名注册及备案_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/domain_name/domain_name"),
      },
      // 域名备案搜索
      {
        path: "/home/domainseach",
        name: "域名注册及备案_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/domain_name/domain_deail"),
      },
      // 运维
      {
        path: "/home/maintenance",
        name: "运维_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/maintenance/maintenance"),
      },
      // ssl证书
      {
        path: "/home/ssl",
        name: "SSL证书_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/ssl/ssl"),
      },
      // ssl证书详情
      {
        path: "/home/ssldetailed",
        name: "证书详情_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/ssl/ssldetailed"),
      },
      {
        path: "/home/certificate_introduce",
        name: "证书简介",
        component: () => import("../views/mobile/index/lingsan/certificate_introduce.vue"),
      },
      //IDC详情
      {
        path: "/IDCparticulars",
        name: "IDC详情_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/IDCparticulars"),
      },
      // 全部app
      {
        path: "/home/allAPP",
        name: "定制开发_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/allAPP"),
      },
      // APPitem
      {
        path: "/home/APPitem",
        name: "APPITEM_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/APPitem"),
      },
      {
        path: "/home/billAppParticulars",
        name: "今日海报详情",
        component: () => import("../views/pc/index/billApp_particulars"),
      },
      // billApp
      {
        path: "/home/BILLitem",
        name: "海报设计_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/billApp"),
      },
      {
        path:"/mobile/itempic",
        name:"海报详情",
        component:() => import("../views/mobile/index/itempic"),
      },
      // 我的
      {
        path: "/my",
        name: "晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/my/my"),
      },
      {
        path: "/positionDetail",
        name: "岗位详情_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/addabout/positionDetail"),
      },

      {
        path: "/IDC",
        name: "IDC服务_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/IDC"),
      },
      // 证书类型详情
      {
        path: "/home/sslTypeDetail",
        name: "证书详情",
        component: () => import("../views/mobile/index/sslTypeDetail"),
      },
      //500页面
      {
        path: "500",
        name: "error_500",
        component: () => import("../views/pc/error/errorserve"),
      },
      //客服
      {
        path: "/home/contact",
        name: "客服_晋城市鼎峰网络科技有限公司",
        component: () => import("../views/pc/index/contact.vue")
      },
      {
        path: "/home/about",
        name: "关于我们_晋城市鼎峰网络科技有限公司",
        redirect: "/home/about/brief",
        component: () => import("../views/pc/index/about"),

        children: [
          {
            path: "/home/about/brief",
            name: "公司简介_晋城市鼎峰网络科技有限公司",
            component: () => import("../views/pc/index/addabout/brief"),
          },
          {
            path: "/home/about/introduce",
            name: "招贤纳士_晋城市鼎峰网络科技有限公司",
            component: () => import("../views/pc/index/addabout/introduce"),
          },
          {
            path: "/home/about/experience",
            name: "企业历程_晋城市鼎峰网络科技有限公司",
            component: () => import("../views/pc/index/addabout/experience"),
          },
          {
            path: "/home/about/leave",
            name: "联系我们_晋城市鼎峰网络科技有限公司",
            component: () => import("../views/pc/index/addabout/leave"),
          },
          {
            path: "/home/about/company",
            name: "公司简介_晋城市鼎峰网络科技有限公司",
            component: () => import("../views/pc/index/addabout/company"),
          },

        ],
      },
      // 个人中心
      {
        path: "/mobile/mypersonal",
        name: "报价记录",
        component: () => import("../views/mobile/personal/mypersonal"),
      },
      // 修改昵称
      {
        path: "/mobile/username",
        name: "修改昵称",
        component: () => import("/src/views/mobile/personal/username"),
      },
      // 简历投递
      {
        path: "/mobile/delivery",
        name: "简历投递",
        component: () => import("/src/views/mobile/index/aboutitem/delivery"),
      },
      // 报价记录
      {
        path: "/mobile/record",
        name: "报价记录",
        component: () => import("../views/mobile/personal/record"),
      },
      // 报价咨询
      {
        path: "/mobile/consulting",
        name: "报价咨询",
        component: () => import("../views/mobile/personal/consulting"),
      },
    ],
  },
  // 咨询详情
  {
    path: "/mobile/consultationSelect",
    name: "咨询详情",
    component: () => import("../views/mobile/personal/consultationSelect"),
  },
  // 登录
  {
    path: "/login",
    name: "登录_晋城市鼎峰网络科技有限公司",
    component: () => import("../views/pc/login/login"),
  },
  // 注册
  {
    path: "/register",
    name: "注册_晋城市鼎峰网络科技有限公司",
    component: () => import("../views/pc/login/register"),
  },
  // 忘记密码
  {
    path: "/forget",
    name: "忘记密码_晋城市鼎峰网络科技有限公司",
    component: () => import("../views/pc/login/forget_password"),
  },

  // 我的-账号设置
  {
    path: "/myzhanghao",
  },
  //404页面
  {
    path: "/*",
    name: "404_晋城市鼎峰网络科技有限公司",
    component: () => import("../views/pc/error/errorpage"),
  },
];

const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

const router = new VueRouter({
  mode: "history",
  routes: pc,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // if(to.name){
  //   document.title = to.name
  // }
  let token = localStorage.getItem("token")
  if(to.path != '/my') return next()
  if(!token) return next('/login')
  // if (to.path === "/") {
  //   router.replace("/home/index");
  // }
  // if (equipmentType && to.path.indexOf("mobile") !== -1) {
  //   router.replace("/home/index");
  // }

  // if (!equipmentType && to.path.indexOf("home") !== -1) {
  //   router.replace("/mobile/index");
  // }

  let path = to.path;
  let queryStr = "";

  if (Object.keys(to.query).length) {
    for (const iterator in to.query) {
      queryStr += `${iterator}=${to.query[iterator]}&`;
    }
  }
  window.sessionStorage.setItem("pc_routePath", `${path}?${queryStr}`);
  if (to.name == null) {
    document.title =
      "晋城网站建设|晋城软件开发|晋城APP开发|晋城小程序开发|晋城商标注册|晋城IDC|晋城手机软件_晋城市鼎峰网络科技有限公司";
  } else {
    document.title = to.name;
  }
  next();
});

export default router;
