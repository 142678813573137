export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouteAlice: true
    };
  },
  created() {
    this.setRem();
  },
  methods: {
    ISMOBILE() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    setRem(n = window) {
      let e, t, i, d, o, a;
      e = n.document;
      t = e.documentElement;
      i = 750; // UI图设计宽度
      d = i / 100;
      o = "orientationchange" in n ? "orientationchange" : "resize";
      a = () => {
        let n = t.clientWidth || 375;
        n > 750 && (n = 375);
        t.style.fontSize = `${n / d}px`;
      };
      if (e.addEventListener) {
        n.addEventListener(o, a, !1);
        e.addEventListener("DOMContentLoaded", a, !1);
      }
    },
    reload() {
      this.isRouteAlice = false;
      this.$nextTick(() => {
        this.isRouteAlice = true;
      });
    }
  }
};