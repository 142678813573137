import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import "wowjs/css/libs/animate.css";
import VueAnimateNumber from "vue-animate-number";
import VueAMap from "vue-amap"; // 高德地图
import 'vant/lib/index.css';
import "swiper/css/swiper.min.css";

import axios from "axios";
Vue.prototype.axios = axios;

import less from "less";
Vue.use(less);
import {
  Popup,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Form,
  Cell,
  CellGroup,
  Field,
  Button,
  Notify,
  Cascader,
  Picker,
  Icon,
  Grid,
  GridItem,
  Uploader,
  Toast,
  Dialog,
  Sidebar,
  SidebarItem,
  Collapse, 
  CollapseItem,
  DatetimePicker ,
  ImagePreview
  
} from "vant";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DatetimePicker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Button);
Vue.use(Notify);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(VueAnimateNumber);
Vue.use(VueAMap);
Vue.use(Popup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(ImagePreview)
VueAMap.initAMapApiLoader({
  key: "4976e1274b692084229e59e4c9b89ac9",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder",
    "AMap.Marker",
    "AMap.Circle",
    "AMap.LngLat",
    "AMap.Pixel",
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4",
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
